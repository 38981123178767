<template>
  <div>
    <table class="table" v-if="done">
      <thead>
        <tr>
          <th>code</th>
          <th>classification</th>
          <tH>Book count</tH>
          <th>&nbsp;</th>
        </tr>
      </thead>
      <tbody>
        <tr v-bind:key="dewey.id" v-for="(dewey,i) in deweys">
          <td>{{ dewey.code }}</td>
          <td>
            <span v-if="!dewey.editing">{{ dewey.name }}</span>
            <input v-else class="input" type="input" v-model="dewey.name" />
          </td>
          <td><router-link :to="{name: 'ByClassification', params: { id: dewey.code }}" v-if="parseInt(dewey.book_count) > 0" target="_blank">{{ dewey.book_count }}</router-link></td>
          <td>
            <button v-if="dewey.editing" @click="submitName(i)" class="button is-small is-primary">Add name</button>
          </td>
        </tr>
      </tbody>
    </table>
    <div v-else>
      <img src="@/assets/ajax-loader.gif"/>
    </div>
  </div>
</template>

<script>

  export default {
    data () {
      return {
        done: false,
        deweys: [],
        editing: {},
        editname: null
      }
    },
    name: 'Deweys',
    methods: {
      submitName(i) {
        this.axios.post("/v1/deweys", {dewey: this.deweys[i] })
          .then(async(response) => {
            if (response.status === 201) {
              console.log('inserting')
              this.deweys.splice(i, 1, {code: response.data.data.attributes.code, name: response.data.data.attributes.name , editing: false})

            }
          })

      }
    },
    mounted () {
      this.axios.get("/v1/housekeeping/dewey_sweep")
        .then((response) => {
          this.deweys = response.data
          this.deweys.forEach((d) => {
            d.editing = (d.name == null)
          })
          this.done = true
        })
    }
  }
</script>